<template>
	<v-row no-gutters dense class="home d-block d-sm-flex">
		<!-- This is the calendar block -->
		<!-- <div class="d-sm-none d-block mobile-tab">
			<v-tabs background-color="transparent" grow>
				<v-tab @click="activateTab(0)"> Calendar </v-tab>
				<v-tab @click="activateTab(1)"> Dashboard </v-tab>
			</v-tabs>
		</div>

		<v-col cols="4" class="calendar col-12 col-lg-4 d-sm-block d-none" :class="{ 'd-block': mobile_tab == 0 }">
			<p class="my-heading d-sm-block d-none">Calendar</p>

			<div v-if="no_data_calendar === true">
				<div class="d-flex align-center justify-center" style="height: 70vh">
					<p class="no-data">No todo's available</p>
				</div>
			</div>

			<div v-if="deliveries.length > 0">
				<transition-group name="slide-in" :style="{ '--total': deliveries.length }">
					<v-row
						v-for="(delivery, index) in deliveries"
						v-bind:key="'delivery' + index"
						no-gutters
						:style="{ '--i': index }"
						class="d-flex flex-column add-remove-item"
					>
						<div v-if="delivery.date < toDay">
							<p class="sub-heading d-flex align-center">Overdue</p>
						</div>
						<div v-else-if="delivery.date === toDay">
							<p class="sub-heading d-flex align-center">Today</p>
						</div>
						<div v-else-if="delivery.date === tomorrow">
							<p class="sub-heading d-flex align-center">Tomorrow</p>
						</div>
						<div v-else>
							<p class="sub-heading d-flex align-center">
								{{ deliveryDate(delivery.date) }}
							</p>
						</div>

						<transition-group name="slide-in" :style="{ '--total': delivery.deliveries.length }">
							<v-row
								v-for="(data, index) in delivery.deliveries"
								v-bind:key="'delivery' + index"
								no-gutters
								:style="{ '--i': index }"
								class="detail-box d-flex align-center add-remove-item"
								v-on:click="bidDelivery(data.bid_id)"
							>
								<v-col cols="8">
									<p class="heading-mf15">
										{{ data.list_name }}
									</p>
									<p class="heading-rf13">Quote ref: {{ data.bid_id }}</p>
								</v-col>
								<v-col cols="4" class="d-flex justify-end align-center">
									<p class="heading-rf13">
										{{ deliveryTime(delivery.date, data.start_time) }}
									</p>
								</v-col>
							</v-row>
						</transition-group>
					</v-row>
				</transition-group>
			</div>
		</v-col> -->

		<!-- This is notification block -->
		<!-- <v-col cols="12" lg="8" class="notification d-sm-block d-none" :class="{ 'd-block': mobile_tab == 1 }">
			<v-row no-gutters class="d-sm-flex d-none">
				<v-col cols="6">
					<p class="my-heading">Notifications</p>
				</v-col>
				<v-col cols="6" class="header-link d-flex justify-end align-center pb-7">
					<span v-bind:class="{ active: filter == 'all' }" v-on:click="filter = 'all'">All</span>
					<span v-bind:class="{ active: filter == 'new_list' }" v-on:click="filter = 'new_list'">New lists</span>
					<span v-bind:class="{ active: filter == 'status_change' }" v-on:click="filter = 'status_change'">Status changes</span>
				</v-col>
			</v-row>

			<div v-if="no_data_notification === true">
				<div class="d-flex align-center justify-center" style="height: 70vh">
					<p class="no-data">No notifications available</p>
				</div>
			</div>

			<div v-if="notifications.length > 0"> -->
		<!-- <div  v-show="getTotalPage(notifications) > 0">
                    <v-data-table
                        :items="notifications"
                        :page.sync="page"
                        :items-per-page="itemsPerPage"
                        hide-default-footer
                        hide-default-header
                        >
        <template v-slot:body="props">-->
		<!-- <transition-group name="slide-in" :style="{ '--total': notifications.length }">
					<div
						v-for="(data, index) in notifications"
						v-bind:key="'notification' + index"
						:style="{ '--i': index }"
						class="notification-box d-flex align-center mb-3"
						:class="data.notification.status === 'UR' ? 'unread-notification' : ''"
						@mouseover="readNotification(index)"
					>
						<v-row no-gutters>
							<v-col cols="12" sm="3" class="TD-block d-flex align-center">
								<v-col cols="2" sm="4" class="pa-0">
									<div v-if="!data.bid.status" class="blue-icon-shadow d-flex align-center justify-center">
										<img :src="$store.state.icons.icons['Exclamation']" alt />
									</div>
									<div v-if="data.bid.status === 'LB' || data.bid.status === 'R'" class="red-icon-shadow d-flex align-center justify-center">
										<img :src="$store.state.icons.icons['RedCross']" alt />
									</div>
									<div v-if="data.bid.status === 'AC'" class="blue-icon-shadow d-flex align-center justify-center">
										<img :src="$store.state.icons.icons['Arrow']" alt />
									</div>
									<div v-if="data.bid.status === 'E'" class="blue-icon-shadow d-flex align-center justify-center">
										<img :src="$store.state.icons.icons['Timer']" alt />
									</div>
									<div v-if="data.bid.status === 'A'" class="green-icon-shadow d-flex align-center justify-center">
										<img :src="$store.state.icons.icons['Tick']" alt />
									</div>
								</v-col>

								<v-col cols="10" sm="8" class="d-flex align-center justify-sm-end justify-space-between pa-0">
									<p class="ma-0 n-date">
										{{ notificationDate(data.notification.created_at) }}
										<br />
										<i class="ma-0 n-time">{{ notificationTime(data.notification.created_at) }}</i>
									</p>

									<div
										class="d-sm-none d-block"
										v-if="(!data.bid.status || data.bid.status === 'R' || data.bid.status === 'E') && data.list.status === 'A'"
									>
										<app-list-hours v-bind:value="createdAt(data.list.idle_time)"></app-list-hours>
									</div>

									<div class="d-sm-none d-block" v-if="data.bid.status === 'AC'">
										<app-bid-hours v-bind:value="createdAt(data.bid.created_at)"></app-bid-hours>
									</div>
								</v-col>
							</v-col>
							<v-col cols="12" sm="9" class="content-block d-sm-flex d-block align-center">
								<v-col cols="12" sm="6" class="pa-0">
									<p v-if="data.list.status === 'E' && !data.bid.status" class="heading-mf15">List has expired</p>
									<p v-else-if="!data.bid.status" class="heading-mf15">New list awaiting for your quote</p>
									<p v-else-if="data.bid.status === 'LB'" class="heading-mf15">Contractor has accepted other quote</p>
									<p v-else-if="data.bid.status === 'E'" class="heading-mf15">Your quote has expired</p>
									<p v-else-if="data.bid.status === 'AC'" class="heading-mf15">Your quote has been submitted</p>
									<p v-else-if="data.bid.status === 'R'" class="heading-mf15">Your quote has been rejected</p>
									<p v-else-if="data.bid.status === 'A'" class="heading-mf15">Your quote has been accepted</p>

									<p v-if="!data.bid.bid_id" class="heading-rf13">
										List ref:
										{{ data.notification.list_id }}
									</p>

									<p v-else class="heading-rf13">Quote ref: {{ data.bid.bid_id }}</p>
								</v-col>
								<v-col cols="12" sm="6" class="d-flex align-sm-center flex-sm-row flex-column my-btn-disabled justify-end pa-0">

									<div
										class="d-sm-block d-none"
										v-if="(!data.bid.status || data.bid.status === 'R' || data.bid.status === 'E') && data.list.status === 'A'"
									>
										<app-list-hours v-bind:value="createdAt(data.list.idle_time)"></app-list-hours>
									</div>

									<div class="d-sm-block d-none" v-if="data.bid.status === 'AC'">
										<app-bid-hours v-bind:value="createdAt(data.bid.created_at)"></app-bid-hours>
									</div>

									<div class="my-btn-disabled">
										<v-btn
											v-if="!data.bid.status"
											class="my-p-btn my_elevation"
											id="my_elevation"
											depressed
											@click="bid(data.list.list_id)"
											:disabled="data.list.status === 'E' || data.list.status === 'BA' || data.notification.unique === false"
											>Quote</v-btn
										>

										<v-btn
											v-else-if="data.bid.status === 'A'"
											class="my-p-btn my_elevation"
											id="my_elevation"
											depressed
											@click="bidDelivery(data.bid.bid_id)"
											>Delivery</v-btn
										>

										<v-btn
											v-else-if="data.bid.status === 'AC'"
											class="my-p-btn my_elevation"
											id="my_elevation"
											depressed
											@click="checkBid(data.bid.bid_id)"
											:disabled="data.list.status === 'E' || data.list.status === 'BA' || data.notification.unique === false"
											>Check Quote</v-btn
										>

										<v-btn
											v-else-if="data.bid.status === 'R' || data.bid.status === 'E'"
											class="my-p-btn my_elevation"
											id="my_elevation"
											depressed
											@click="reBid(data.bid.bid_id)"
											:disabled="data.list.status === 'E' || data.list.status === 'BA' || data.notification.unique === false"
											>Re-Quote</v-btn
										>

										<v-btn
											v-else-if="data.bid.status === 'LB' || data.list.status === 'BA'"
											class="my-p-btn my_elevation"
											id="my_elevation"
											depressed
											disabled
											>Lost-Quote</v-btn
										>
									</div>
								</v-col>
							</v-col>
						</v-row>
					</div>
				</transition-group> -->

		<!-- <v-menu top auto offset-x min-width="200px" :close-on-click="closeOnClick">
					<template v-slot:activator="{ on, attrs }">
						<div icon class="d-sm-none text-end filter-icon">
							<i v-if="!filter_exit" @click="filter_exit = !filter_exit" class="mdi mdi-filter-outline icon-circle" v-bind="attrs" v-on="on" />
							<i v-else @click="filter_exit = !filter_exit" class="mdi mdi-close icon-circle" v-bind="attrs" v-on="on" />
						</div>
					</template>

					<v-list width="200px">
						<v-list-item>
							<v-list-item-title>
								<span v-bind:class="{ active: filter == 'all' }" v-on:click=";(filter = 'all'), (filter_exit = !filter_exit)">All</span>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<span v-bind:class="{ active: filter == 'new_list' }" v-on:click=";(filter = 'new_list'), (filter_exit = !filter_exit)"
									>New lists</span
								>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<span
									v-bind:class="{ active: filter == 'status_change' }"
									v-on:click=";(filter = 'status_change'), (filter_exit = !filter_exit)"
									>Status changes</span
								>
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</div> -->
		<!-- </template>
                    </v-data-table>
                
                    <div class="text-center pt-4">
                        <v-pagination 
                            v-model="page" 
                            color="#2974FF"
                            :total-visible="7"
                            :length="getTotalPage(notifications)"
                            ></v-pagination>
                    </div>
        </div>-->
		<!-- </v-col> -->
		<v-col cols="12" class="notification" v-if="dashboardData">
			<v-row no-gutters class="d-sm-flex d-none">
				<v-col cols="6">
					<p class="my-heading">Dashboard</p>
				</v-col>
			</v-row>
			<v-row no-gutters>
				<v-col cols="12">
					<p class="delivery-sf20">Total Transactions</p>
				</v-col>
				<v-col cols="12" sm="6" md="4" lg="3" class="pb-8 pr-4 pl-4" v-for="(value, key, index) in dashboardData.dashboard" :key="index">
					<div class="my-card-1 d-flex" @click="displayNewQuote(key)" :class="{ 'cursor-pointer': key === 'New Quotes' }">
						<div class="small-card-1 d-flex align-center justify-start">
							<img :src="require('@/assets/images/' + key.replace(/\s+/g, '_').toLowerCase() + '.png')" />
						</div>
						<div class="small-card-2 d-flex flex-column align-center justify-center">
							{{ value ? value : 0 }}
							<p class="heading-sf15">{{ changeCase(key) }}</p>
						</div>
					</div>
				</v-col>
			</v-row>

			<v-row no-gutters>
				<v-col cols="12">
					<p class="delivery-sf20">Total Sales</p>
				</v-col>
				<v-col cols="12" sm="6" md="4" lg="3" class="pb-8 pr-4 pl-4">
					<div class="my-card-1 d-flex">
						<div class="small-card-1 d-flex align-center justify-start">
							<img src="@/assets/images/sales.png" />
						</div>
						<div class="small-card-2 d-flex flex-column align-center justify-center">
							{{ dashboardData.total_sales ? $options.filters.currency(dashboardData.total_sales) : 0 }}
							<p class="heading-sf15">Total Sales</p>
						</div>
					</div>
				</v-col>
			</v-row>

			<v-row no-gutters>
				<v-col cols="12">
					<p class="delivery-sf20">Deliveries</p>
				</v-col>
				<v-col cols="12" sm="6" md="4" lg="3" class="pb-8 pr-4 pl-4" v-for="(value, key, index) in dashboardData.delivery" :key="index">
					<router-link to="/deliveries" style="text-decoration: none">
						<div class="my-card-1 d-flex">
							<div class="small-card-1 d-flex align-center justify-start">
								<img :src="require('@/assets/images/' + key.replace(/\s+/g, '_').toLowerCase() + '.png')" />
							</div>
							<div class="small-card-2 d-flex flex-column align-center justify-center">
								{{ value ? value : 0 }}
								<p class="heading-sf15">{{ changeCase(key) }}</p>
							</div>
						</div>
					</router-link>
				</v-col>
			</v-row>
		</v-col>

		<app-bid ref="bid"></app-bid>
		<app-re-bid ref="rebid"></app-re-bid>
		<app-check-bid ref="checkbid"></app-check-bid>
		<app-bid-delivery ref="deliverybid"></app-bid-delivery>
	</v-row>
</template>

<script>
const Bid = () => import('../bidcomponent/Bid')
const ReBid = () => import('../bidcomponent/ReBid')
const CheckBid = () => import('../bidcomponent/CheckBid')
const BidDelivery = () => import('../bidcomponent/BidDelivery')
import moment from 'moment'
import Cookies from 'js-cookie'
import { getNotifications, getRecentNotifications, readNotification, getDashboardData } from '../../services/homeservice'
import { getDeliveryCalendar } from '../../services/deliveryservice'
import { errorLogs } from '../../services/authservices'
import { getDeviceId } from '../../services/firebase'
import { localDateFromDateTime, covLocalTime, localTimeFromDateTime, timeFormat } from '../../services/commonservice'
import { mapGetters } from 'vuex'
export default {
	components: {
		AppBid: Bid,
		AppReBid: ReBid,
		AppCheckBid: CheckBid,
		AppBidDelivery: BidDelivery,
	},

	data() {
		return {
			filter: 'all',
			page: 1,
			my_timer: 0,
			new_list: true,
			no_data_calendar: false,
			no_data_notification: false,
			itemsPerPage: 25,
			unique_id: [],
			delivery_data: [],
			notification_data: [],
			tab: null,
			mobile_tab: 0,
			filter_exit: false,
			closeOnClick: false,
			dashboard: {
				accepted_quotes: null,
				rejected_quotes: null,
				expired_quotes: null,
				pending_quotes: null,
			},
			total_sales: null,
			delivery: {
				Scheduled: null,
				Delivered: null,
			},
		}
	},

	async mounted() {
		// Set fcm token
		if (!this.$store.state.auth.fcm_id.includes(Cookies.get('fcm_id'))) {
			getDeviceId()
		}

		await this.$store.dispatch('getDashboardData')

		let data = await getNotifications()
		let dataset = data.dataset
		if (dataset && dataset.length > 0) {
			dataset.forEach((item) => {
				let listitems = []
				let biditems = []
				let reason_ids = []
				let list = {}
				let bid = {}
				let contractor = {}
				let notification = {}

				item.clist.listitems.forEach((item) => {
					let ob = {
						name: item.name,
						qty: item.qty,
						price: 0.0,
						notes: null,
						show_note: false,
						url: item.url,
					}
					listitems.push(ob)
				})

				listitems.forEach((item) => {
					let data = item.notes != null
					item['show_note'] = data
				})

				list = {
					list_id: item.clist.list_id,
					contractor_id: item.clist.contractor_id,
					category_id: item.clist.category_id,
					list_name: item.clist.list_name,
					delivery_date: item.clist.delivery_date,
					pickup: item.clist.pickup,
					address: item.clist.address,
					delivery_time: item.clist.delivery_time,
					notes: item.clist.notes,
					status: item.clist.status,
					idle_time: item.clist.idle_time,
					latitude: item.clist.latitude,
					longitude: item.clist.longitude,
					listitems: listitems,
				}

				if (item.bid) {
					if (item.bid.reason_ids) {
						item.bid.reason_ids.forEach((item) => {
							reason_ids.push(item)
						})
					} else {
						reason_ids.push(0)
					}

					item.bid.biditems.forEach((item) => {
						let ob = {
							name: item.name,
							qty: item.qty,
							price: item.price,
							notes: item.notes,
							modification: item.modification,
							show_note: false,
							url: item.url,
						}

						biditems.push(ob)
					})

					biditems.forEach((item) => {
						let data = item.notes != null
						item['show_note'] = data
					})

					bid = {
						bid_id: item.bid.bid_id,
						list_id: item.bid.list_id,
						date_of_delivery: item.bid.date_of_delivery,
						sub_total: item.bid.sub_total,
						shipping: item.bid.shipping,
						service_fee: item.bid.service_fee,
						sales_tax: item.bid.sales_tax,
						tax_percentage: item.bid.tax_percentage,
						total_amount: item.bid.total_amount,
						modification: item.bid.modification,
						reason_ids: reason_ids,
						comments: item.bid.comments,
						tag_status: item.bid.tag_status,
						status: item.bid.status,
						created_at: item.bid.created_at,
						time_of_delivery: item.bid.time_of_delivery,
						latitude: item.bid.latitude,
						longitude: item.bid.longitude,
						start_time: item.bid.start_time,
						end_time: item.bid.end_time,
						biditems: biditems,
					}
				}

				if (item.clist.contractor) {
					contractor = {
						fullname: item.clist.contractor.fullname,
						phone: item.clist.contractor.phone,
					}
				}

				notification = {
					notification_id: item.notification_id,
					status: item.status,
					bid_id: item.bid_id,
					list_id: item.list_id,
					created_at: item.created_at,
				}

				let ob = {
					list: list,
					bid: bid,
					contractor: contractor,
					notification: notification,
				}

				this.notification_data.push(ob)
			})
		} else {
			this.no_data_notification = true
		}

		await this.getCalendarData()
	},

	created() {
		this.$store.watch(
			(state) => {
				return state.list
			},
			() => {
				this.updateList()
			},
			{ deep: true }
		),
			this.$store.watch(
				(state) => {
					return state.list.delivered
				},
				() => {
					this.getCalendarData()
				},
				{ deep: true }
			)

		if (this.$store.state.auth.not_supported) {
			this.autoUpdate()
		}
	},

	beforeDestroy() {
		clearInterval(this.my_timer)
	},

	computed: {
		...mapGetters({
			dashboardData: 'getDashboardData',
		}),

		notifications() {
			if (this.notification_data.length > 0) {
				this.unique_id = []
				this.notification_data.forEach((item) => {
					if (this.unique_id.includes(item.notification.list_id)) {
						item.notification['unique'] = false
					} else {
						this.unique_id.push(item.notification.list_id)
						item.notification['unique'] = true
					}
				})

				let notifications = {}
				if (this.filter === 'all') {
					notifications = this.notification_data.filter((item) => {
						return item.notification.unique === true
					})
				} else if (this.filter === 'new_list') {
					notifications = this.notification_data.filter((item) => {
						return !item.bid.status && item.list.status === 'A' && item.notification.unique === true
					})
				} else if (this.filter === 'status_change') {
					notifications = this.notification_data.filter((item) => {
						if (item.bid.status || (item.list.status === 'E' && item.notification.unique === true)) {
							return true
						}
					})
				}

				this.no_data_notification = notifications.length > 0 ? false : true
				if (this.no_data_notification === false) {
					this.$store.state.list.new_notifications = notifications.filter((data) => data.notification.status === 'UR').length
				}
				return notifications
			} else {
				return this.notification_data
			}
		},

		deliveries() {
			return this.delivery_data
		},

		toDay() {
			return moment(Date.now()).utc().format('YYYY-MM-DD')
		},

		tomorrow() {
			return moment(Date.now()).add('1', 'days').utc().format('YYYY-MM-DD')
		},
	},

	methods: {
		changeCase(text) {
			var result = text.replace(/_/g, ' ')
			return result.replace(/\w\S*/g, function (txt) {
				return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
			})
		},

		displayNewQuote(key) {
			if (key === 'New Quotes') {
				this.$store.state.list.is_new_list_filter = true
				this.$router.push('/lists')
			}
		},

		activateTab(tab) {
			this.mobile_tab = tab
		},

		createdAt(date) {
			return date
		},

		notificationDate(date) {
			return localDateFromDateTime(date)
		},

		notificationTime(time) {
			return localTimeFromDateTime(time)
		},

		deliveryTime(date, time) {
			return timeFormat(covLocalTime(date, time))
		},

		deliveryDate(date) {
			return localDateFromDateTime(date)
		},

		getTotalPage(data) {
			return Math.ceil(data.length / this.itemsPerPage)
		},

		updateList() {
			errorLogs('update list function call in home page')
			errorLogs('list_id : ' + this.$store.state.list.list_data.list.list_id)
			if (this.$store.state.list.list_data.list.list_id != '') {
				this.$store.commit('listBidAccepted', {
					list_id: '',
					status: '',
				})
				let data = JSON.parse(JSON.stringify(this.$store.state.list.list_data))
				this.notification_data.forEach((item, index) => {
					if (item.list.list_id == data.list.list_id) {
						this.notification_data[index] = data
						errorLogs('notification replace')
						this.new_list = false
						if (data.bid.status === 'A') {
							this.getCalendarData()
						}
					}
				})

				if (this.new_list === true) {
					this.notification_data.splice(0, 0, data)
				}

				this.filter = ''
				this.filter = 'all'
			}

			if (this.notification_data.length > 0) {
				if (this.$store.state.list.cancel_list.list_id != '') {
					errorLogs(this.$store.state.list.cancel_list)
					this.notification_data.forEach((item, index) => {
						if (item.list.list_id == this.$store.state.list.cancel_list.list_id) {
							this.notification_data.splice(index, 1)
							errorLogs('remove list')
						}
					})

					this.filter = ''
					this.filter = 'all'
				}

				if (this.$store.state.list.list_bid_accepted.list_id != '') {
					this.notification_data.forEach((item, index) => {
						if (item.list.list_id == this.$store.state.list.list_bid_accepted.list_id) {
							if (this.$store.state.list.list_bid_accepted.status != '') {
								errorLogs('list status change')
								item.list.status = this.$store.state.list.list_bid_accepted.status
								if (this.$store.state.list.list_bid_accepted.status != 'A') {
									if (!item.bid.status) {
										this.notification_data.splice(index, 1)
									}
								}
								//this.$store.commit('listBidAccepted', {list_id: '', status: ''});
							}
						}
					})

					this.filter = ''
					this.filter = 'all'
				}

				if (this.$store.state.list.reset_list_counter.list_id != '') {
					this.notification_data.forEach((item, index) => {
						if (item.list.list_id == this.$store.state.list.reset_list_counter.list_id) {
							if (this.$store.state.list.reset_list_counter.idle_time != '') {
								errorLogs('list idle time change')
								item.list.idle_time = this.$store.state.list.reset_list_counter.idle_time
								//this.$store.commit('resetListCounter', {list_id: '', idle_time: ''});
							}
						}
					})

					this.filter = ''
					this.filter = 'all'
				}
			}
		},

		getList(type, id) {
			let data = {}
			if (type === 'list') {
				data = this.notification_data.filter((list) => {
					return list.list.list_id === id
				})
			} else if (type === 'bid') {
				data = this.notification_data.filter((bid) => {
					return bid.bid.bid_id === id
				})
			}

			return {
				list: data[0]['list'],
				bid: data[0]['bid'],
				contractor: data[0]['contractor'],
				notification: data[0]['notification'],
			}
		},

		bid(list_id) {
			let data = this.getList('list', list_id)
			this.$store.commit('listReset')
			this.$store.commit('listData', JSON.parse(JSON.stringify(data)))
			this.$refs.bid.bidSubmit(list_id)
		},

		reBid(bid_id) {
			let data = this.getList('bid', bid_id)
			this.$store.commit('listReset')
			this.$store.commit('listData', JSON.parse(JSON.stringify(data)))
			this.$refs.rebid.reBidSubmit(bid_id)
		},

		checkBid(bid_id) {
			let data = this.getList('bid', bid_id)
			this.$store.commit('listReset')
			this.$store.commit('listData', JSON.parse(JSON.stringify(data)))
			this.$refs.checkbid.checkBidSubmit(bid_id)
		},

		bidDelivery(bid_id) {
			this.$refs.deliverybid.deliverySubmit(bid_id)
		},

		readNotification(index) {
			setTimeout(() => {
				let notification = this.notifications[index].notification
				if (notification.status === 'UR') {
					this.$store.commit('readNotification')
					this.notifications[index].notification.status = 'R'
					let notification_id = notification.notification_id
					readNotification(notification_id)
				}
				return true
			}, 500)
		},

		async getCalendarData() {
			let delivery = await getDeliveryCalendar()
			let dataset1 = delivery.dataset
			this.delivery_data = []
			if (dataset1 && dataset1.length > 0) {
				dataset1.forEach((data) => {
					let delivereis = []
					data.delivery_data.forEach((item) => {
						let delivery = {}

						delivery.delivery_id = item.delivery_id
						delivery.status = item.status
						delivery.bid_id = item.bid.bid_id
						delivery.list_id = item.clist.list_id
						delivery.start_time = item.bid.start_time
						delivery.list_name = item.clist.list_name
						delivery.list_lat = item.clist.latitude
						delivery.list_long = item.clist.longitude
						delivery.bid_lat = item.bid.latitude
						delivery.bid_long = item.bid.longitude

						delivereis.push(delivery)
					})
					let ob = {
						date: data.date,
						deliveries: delivereis,
					}
					this.delivery_data.push(ob)
				})

				this.no_data_calendar = this.delivery_data.length > 0 ? false : true
			} else {
				this.no_data_calendar = true
			}
		},

		async autoUpdate(val) {
			this.my_timer = setInterval(() => {
				// Notifications
				this.recentNotifications()
			}, 30000)
		},

		async recentNotifications() {
			let data = await getRecentNotifications()
			let dataset = data.dataset
			if (dataset && dataset.length > 0) {
				dataset.forEach((item) => {
					let listitems = []
					let biditems = []
					let reason_ids = []
					let list = {}
					let bid = {}
					let contractor = {}
					let notification = {}

					item.clist.listitems.forEach((item) => {
						let ob = {
							name: item.name,
							qty: item.qty,
							price: 0.0,
							notes: null,
							show_note: false,
							url: item.url,
						}
						listitems.push(ob)
					})

					listitems.forEach((item) => {
						let data = item.notes != null
						item['show_note'] = data
					})

					list = {
						list_id: item.clist.list_id,
						contractor_id: item.clist.contractor_id,
						category_id: item.clist.category_id,
						list_name: item.clist.list_name,
						delivery_date: item.clist.delivery_date,
						pickup: item.clist.pickup,
						address: item.clist.address,
						delivery_time: item.clist.delivery_time,
						notes: item.clist.notes,
						status: item.clist.status,
						idle_time: item.clist.idle_time,
						latitude: item.clist.latitude,
						longitude: item.clist.longitude,
						listitems: listitems,
					}

					if (item.bid) {
						if (item.bid.reason_ids) {
							item.bid.reason_ids.forEach((item) => {
								reason_ids.push(item)
							})
						} else {
							reason_ids.push(0)
						}

						item.bid.biditems.forEach((item) => {
							let ob = {
								name: item.name,
								qty: item.qty,
								price: item.price,
								notes: item.notes,
								modification: item.modification,
								show_note: false,
								url: item.url,
							}

							biditems.push(ob)
						})

						biditems.forEach((item) => {
							let data = item.notes != null
							item['show_note'] = data
						})

						bid = {
							bid_id: item.bid.bid_id,
							list_id: item.bid.list_id,
							date_of_delivery: item.bid.date_of_delivery,
							sub_total: item.bid.sub_total,
							shipping: item.bid.shipping,
							service_fee: item.bid.service_fee,
							sales_tax: item.bid.sales_tax,
							tax_percentage: item.bid.tax_percentage,
							total_amount: item.bid.total_amount,
							modification: item.bid.modification,
							reason_ids: reason_ids,
							comments: item.bid.comments,
							tag_status: item.bid.tag_status,
							status: item.bid.status,
							created_at: item.bid.created_at,
							time_of_delivery: item.bid.time_of_delivery,
							latitude: item.bid.latitude,
							longitude: item.bid.longitude,
							start_time: item.bid.start_time,
							end_time: item.bid.end_time,
							biditems: biditems,
						}
					}

					if (item.clist.contractor) {
						contractor = {
							fullname: item.clist.contractor.fullname,
							phone: item.clist.contractor.phone,
						}
					}

					notification = {
						notification_id: item.notification_id,
						status: item.status,
						bid_id: item.bid_id,
						list_id: item.list_id,
						created_at: item.created_at,
					}

					let ob = {
						list: list,
						bid: bid,
						contractor: contractor,
						notification: notification,
					}

					if (ob.bid.status === 'A') {
						this.getCalendarData()
					}

					let noti_index = this.notification_data.findIndex((noti) => noti.list.list_id == ob.list.list_id)

					if (noti_index >= 0) {
						this.notification_data[noti_index] = ob
					} else {
						this.notification_data.splice(0, 0, ob)
					}

					this.filter = ''
					this.filter = 'all'
				})
			}
		},
	},
}
</script>

<style lang="scss">
.home {
	min-height: 100vh;
	margin-top: -80px;
	padding-top: 80px;
	height: 100%;

	.my-heading {
		margin-bottom: 0px !important;
		padding-bottom: 30px;
	}

	.calendar {
		padding: 32px !important;
		background: #f0f3f8;
		border-right: 1px solid #dde2ee;

		.sub-heading {
			background: #e7ebf3;
			margin: 0px -32px 0px -32px;
			padding: 0px 32px 0px 32px;
			height: 32px;
			font-family: Inter-Bold;
			font-size: 13px;
			color: #216fff;
			z-index: 5;
			margin-top: -1px;
		}

		.detail-box {
			height: 74px;
			cursor: pointer;
			border-bottom: 1px solid #dde2ee;
		}
	}
	.notification {
		padding: 32px !important;
		background: #f5f8fb;

		.notification-box {
			background: #ffffff;
			box-shadow: 0 4px 8px 0 rgba(133, 156, 171, 0.15);
			border-radius: 8px;
			min-height: 88px;
			z-index: 7;
			.TD-block {
				padding: 24px;
				border-right: 1px solid #e7eaf3;
			}

			img {
				height: 16px;
			}

			.n-date {
				font-family: Inter-Medium;
				font-size: 13px;
				color: #576171;
			}

			.n-time {
				font-family: Inter-Regular;
				font-size: 13px;
				color: #7a89a4;
				font-style: normal;
				line-height: 16px;
			}

			.content-block {
				padding: 24px;
			}
		}

		.unread-notification {
			background: #dcdcdc !important;
			cursor: pointer;
		}
	}
	.mobile-tab {
		width: 90%;
		margin: 28px auto auto auto;
		background-color: #dde2ee8f;
	}
	.v-tab {
		text-transform: capitalize;
	}
	.v-tab--active {
		background-color: #2974ff;
		color: white !important;
		text-transform: capitalize;
		letter-spacing: 1px;
		border-radius: 4px;
		transition: all 0.3s;
	}
	.v-tabs-slider {
		display: none;
	}
}

@media only screen and (max-width: 600px) {
	.home .notification .notification-box .TD-block {
		border-bottom: 1px solid #e7eaf3;
	}
	.filter-icon {
		font-size: 30px;
		color: #2974ff;
		margin-top: 25px;
	}
	.icon-circle {
		background-color: white;
		padding: 10px 10px 5px;
		border-radius: 50%;
		box-shadow: 0 8px 16px 0 #d2d0d0;
	}
	.v-menu__content.theme--light.v-menu__content--auto.menuable__content__active {
		left: auto !important;
		right: 100px;
	}
	.home .notification {
		padding: 21px !important;
	}
}

.my-card-1 {
	background: #ffffff;
	color: #212121;
	box-shadow: 0 16px 32px 0 rgba(133, 156, 171, 0.15) !important;
	border-radius: 8px !important;
	min-height: 80px !important;
}
.small-card-1 {
	padding: 8px 0px 8px 26px;
	width: 72px;
	font-family: Inter-SemiBold;
	font-size: 40px;

	img {
		height: 40px;
		filter: invert(66%) sepia(13%) saturate(418%) hue-rotate(176deg) brightness(98%) contrast(85%);
	}
}

.small-card-2 {
	padding: 8px 16px;
	width: 100%;
	font-family: Inter-SemiBold;
	font-size: 20px;
	color: #212121;

	.heading-sf15 {
		color: #7587a3;
	}
}
</style>
